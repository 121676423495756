/* global window */

// Did not work as desired....
import './featured_products.scss';
const utils = require('public/js/helpers/utils');

var THE_SCRIPT = function () {
	const $onReady = require('public/js/lib/on-ready');
	const { trackImpressionsEvent } = require('public/js/lib/adobe-dtm-helper');

	$onReady({rp$, window,
		label: 'featured_products',
		fn: function () {
			let $featured_products_slider, timeout_val, $featured_product;

			$featured_product = rp$('.featured-product');
			if ($featured_product) {
				// Track Adobe DTM featured product impression
				trackImpressionsEvent();
			}

			if(window.tracking.google.use_gtag_admind_analytics){
				rp$('.featured-product').on('click', 'a', function HookProductClick(evt) {
					const product = {
						sku_stripped: $featured_product.attr('data-sku'),
						name: $featured_product.attr('data-name'),
						brand: $featured_product.attr('data-brand'),
						category: 'Unavailable',
						price: $featured_product.attr('data-price'),
						quantity: 1,
						page: 'Featured Product',
					};
					window.rp_app.track.track_ec_general('select_item', {
						currency:window.rp_app.cart._currency_code,
						items:utils.convertNamesOfItemsForGA([product])
					});
				});
			}

			$featured_products_slider = rp$('.featured-products-slider');

			if($featured_products_slider) {
				timeout_val = $featured_products_slider.data('timeout');
				if (!timeout_val) {
					timeout_val = 4000;
				}

				// attach owl carousel if in carousel mode
				$featured_products_slider.find('ul').addClass('owl-carousel').owlCarousel({
					autoplay: false,
					autoplayTimeout: timeout_val,
					loop: true,
					dots: true,
					lazyLoad: true,
					nav: true,
					items: 1,
				});
			}
		},
	});
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	if (typeof __CURRENT_SCRIPT__ === 'undefined') {
		throw new Error('__CURRENT_SCRIPT__ is not defined');
	}
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
